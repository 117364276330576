import { Button, Col, Form, Row, Typography } from "antd";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import i18n from "../../../i18n/i18n";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";
import './NewPassword.scss';

const { Title } = Typography;

class NewPassword extends Component<any> {
  public static defaultProps: Partial<any> = {};

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {
      match: { params },
      validateToken,
    } = this.props;

    /**
     * description check if token valid change status validateTokenSuccess and set project
     */
    validateToken(params.token, params.project);
  }

  /**
   * @description send email
   * @param email
   */
  handleSubmit = (data: any) => {
    const {
      match: { params },
    } = this.props;
    this.props.setNewPassword(data["password"], params.token, params.project);
  };

  render = () => {
    const { form, history, newPasswordSuccess, validateTokenSuccess } =
      this.props;
    return (
      <div className="wrapper">
        {validateTokenSuccess ? (
          <Title className="page-titel">
            <Translation>
              {(t) => t("login:setNewPassword.titleSuccess")}
            </Translation>{" "}
          </Title>
        ) : (
          <Title className="page-titel">
            <Translation>
              {(t) => t("login:setNewPassword.titleFail")}
            </Translation>{" "}
          </Title>
        )}

        <Row>
          <Col xs={0} sm={0} md={1} lg={2} xl={2} />
          <Col
            className="login-form-fields"
            xs={24}
            sm={24}
            md={22}
            lg={20}
            xl={20}
          >
            <Row>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
              <Col
                className="login-form-fields"
                xs={24}
                sm={24}
                md={14}
                lg={10}
                xl={10}
              >
                {validateTokenSuccess ? (
                  <>
                    <p>
                      {!newPasswordSuccess &&
                        i18n.t("login:setNewPassword.text")}
                    </p>
                    <NewPasswordForm
                      form={form}
                      newPasswordSuccess={newPasswordSuccess}
                      handleSubmit={this.handleSubmit}
                    />
                  </>
                ) : (
                  <>
                    <p>{i18n.t("login:setNewPassword.linkExpired")}</p>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <Button
                          style={{ width: "100%" }}
                          className="btn-special"
                          type="primary"
                          onClick={() => {
                            history.push("/reset-password");
                          }}
                        >
                          {i18n.t("login:button.resetPassword")}
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={0} lg={0} xl={2} />
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <Button
                          type="primary"
                          className="btn-special"
                          ghost
                          style={{ width: "100%" }}
                          onClick={() => {
                            history.push("/signin");
                          }}
                        >
                          {i18n.t("login:toLogin")}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
            </Row>
          </Col>
        </Row>
      </div>
    );
  };
}

const NewPasswordPage = Form.create<any>({ name: "set-new-password" })(
  NewPassword
);
const mapStateToProps = (state: any) => {
  return {
    newPasswordSuccess: state.auth.newPasswordSuccess,
    validateTokenSuccess: state.auth.validateTokenSuccess,
  };
};

const mapDispatchToProps = (dispatch: (arg: any) => any): any => {
  return {
    setNewPassword: (password: string, token: string, project: string) =>
      dispatch(actions.setNewPassword(password, token, project)),
    validateToken: (token: string, project: string = "") =>
      dispatch(actions.validateToken(token, project)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()<any>(NewPasswordPage));
