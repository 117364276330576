import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import './Login.scss';
import { Typography, Form, Row, Col, Alert } from 'antd';
import i18n from '../../../i18n/i18n';
import { Translation, withTranslation } from 'react-i18next';
import LoginForm from './../../components/LoginForm/LoginForm';
import {
  ILoginProps,
  ILoginState,
  IStateMapStateToProps,
} from './../../models/ILogin';

const { Title, Text } = Typography;
class Login extends Component<ILoginProps, ILoginState> {
  public static defaultProps: Partial<ILoginProps> = {};

  constructor(props: any) {
    super(props);

    this.state = {
      isSignup: true,
      email: '',
      password: '',
      formErrors: { email: '', password: '' },
    };
  }

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }
  /**
   *
   */
  handleSubmit = (form: any) => {
    this.props.onAuth(form.email, form.password, true);
  };

  /**
   *
   */
  myChangeHandler: React.FormEventHandler<HTMLInputElement> = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState<any>({ [name]: val });
  };

  /**
   * @method switch Auth Mode
   */
  switchAuthModeHandler = () => {
    this.setState((prevState) => {
      return { isSignup: !prevState.isSignup };
    });
  };

  render = () => {
    // AUTH TRIGGER
    if (this.props.isAuthenticated) {
      this.props.history.push('/dashboard');
    }

    return (
      <div className="wrapper">
        <Title className="page-titel">
          <Translation>{(t) => <span>{t('login:title')}</span>}</Translation>
        </Title>

        <Row>
          <Col xs={0} sm={0} md={1} lg={2} xl={2} />
          <Col
            className="login-form-fields"
            xs={24}
            sm={24}
            md={22}
            lg={20}
            xl={20}
          >
            <Row>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
              <Col
                className="login-form-fields"
                xs={24}
                sm={24}
                md={14}
                lg={10}
                xl={10}
              >
                {/**
                 * @description show error message if api resonse fail
                 */}
                {this.props.loginError && (
                  <Alert
                    message={i18n.t('login:error.loginMessage')}
                    type="error"
                  />
                )}
                <LoginForm
                  form={this.props.form}
                  handleSubmit={this.handleSubmit}
                />
              </Col>
              <Col xs={0} sm={0} md={5} lg={7} xl={7} />
            </Row>

            <Alert
              style={{ margin: 10 }}
              message={
                <Row>
                  <Col className="alert-message">
                    {window._env_.THEME_ENV === 'comjoo' ? (
                      <Text>
                        {i18n.t('login:activationConstraint')}
                        <Link to="/register">
                          {' '}
                          {i18n.t('login:goToActivation')}
                        </Link>
                      </Text>
                    ) : (
                      <Text>
                        {i18n.t('login:registrationConstraint')}:{' '}
                        <a href="https://sanecum.de/telemedizin/patientenregistrierung">
                          {i18n.t('login:goToRegistration')}
                        </a>
                      </Text>
                    )}
                  </Col>
                </Row>
              }
              type="info"
              showIcon
              closable
              banner
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={2} xl={2} />
        </Row>
      </div>
    );
  };
}

const LoginPage = Form.create<ILoginProps>({ name: 'normal_login' })(Login);

const mapStateToProps = (state: IStateMapStateToProps) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    loginError: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch: (arg: any) => any): any => {
  return {
    onAuth: (email: string, password: string, isSignup: boolean) =>
      dispatch(actions.auth(email, password, isSignup)),
    //onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/change-password')),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()<any>(LoginPage));
