import qs from 'querystring';
import * as actionTypes from './actionTypes';
import config from './../../config/client';
import { fhirClient, ssoClient, userClient } from './../../core/auth/services/axios';
import jwt_decode from "jwt-decode";
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import axios from 'axios';
//import keycloak from '../../core/auth/services/keycloak';

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: "login-required",
};
//const Keycloak = require('keycloak-js');

const keycloakConfig: KeycloakConfig = {
  url: 'https://sso.comjoo.net/auth',
  realm: 'comjoo-hub',
  clientId: 'easy-web',
};
const keycloak: any = Keycloak(keycloakConfig);

const ssoUrl = config.keycloak.url;
const userUrl = config.userClient.url;

/**
 * @method authStart
 */
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

/**
 *
 * @method authSuccess
 * @param token
 * @param userId
 */
export const authSuccess = (token: string, userId: any, refresh_token: string) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    token: token,
    access_token: token,
    refresh_token: refresh_token,
    userId: userId,
  };
};

/**
 *
 * @method authFail
 * @param error
 */
export const authFail = (error: any) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

/**
 * @method logout
 * @description clear local Storage and change state
 */
export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  keycloak.logout();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

/**
 *
 * @method checkAuthTimeout
 * @param expirationTime
 */
export const checkAuthTimeout = (expirationTime: any) => {
  return (dispatch: any) => {
    setTimeout(() => {
      // TODO dispatch(logout());
    }, expirationTime * 1000);
  };
};

/**
 *
 * @method auth
 * @param email
 * @param password
 * @param isSignup
 */
export const auth = (email: string, password: string, isSignup: any) => {
  return async (dispatch: any) => {
    dispatch(authStart());
    let url = userUrl + '/user/login';//ssoUrl + '/realms/comjoo-hub/protocol/openid-connect/token';
    if (!isSignup) {
      url = userUrl + '/user/login';//ssoUrl + '/realms/comjoo-hub/protocol/openid-connect/token';
    }

    const authCredentials = {
      username: email,
      password: password,
      // old sso grant_type: 'password',
      // old ssoclient_id: 'hub-connect',
    };

    try {
      const response = await ssoClient.post(
        url,
        qs.stringify(authCredentials),
        config
      );
      var decoded: any = jwt_decode(response.data.access_token);
      if (decoded && decoded?.changePassword === 'true') {
        localStorage.setItem('tempPassword', password)
        dispatch(setAuthRedirectPath('change-password'))
      }

      /**
       * fallback change password flag
       */
      // changePassword

      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem(
        'expirationDate',
        new Date(
          new Date().getTime() + response.data.expires_in * 1000
        ).toString()
      );
      dispatch(
        authSuccess(response.data.access_token, response.data.access_token, 'reüose')
      );
      dispatch(checkAuthTimeout(response.data.expiresIn));
    } catch (error: any) {
      dispatch(authFail(error.response.data.error));
    }
  };
};

/**
 *
 * @param path
 */
export const setAuthRedirectPath = (path: string) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

/**
 * @method authCheckState
 * @description Authentication logic
 */
export const authCheckState = () => {
  return (dispatch: any) => {

    console.log(keycloak)


    keycloak.init({ onLoad: 'login-required', checkLoginIframe: false, }).then((authenticated: any) => {
      const userId = localStorage.getItem('userId');
      if (!keycloak.idToken || !keycloak.refreshToken) {
        dispatch(logout());
      } else {
        //console.log('can check in')
        //console.log(keycloak)
        //let decodeToken: any = jwt_decode(keycloak?.idToken);
        //console.log(keycloak?.idToken)
        //console.log(keycloak?.refreshToken)
        //console.log(keycloak.auth_time)
        //  console.log('Time', keycloak.auth_time.getTime())
        // fill interceptor with Auth 
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak?.idToken;
        ssoClient.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak?.idToken;
        fhirClient.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak?.idToken;
        //console.log(decodeToken)
        //console.log(new Date(decodeToken.auth_time))
        //console.log(new Date(decodeToken.auth_time).getTime())
        //console.log(new Date(decodeToken.exp))
        //console.log(new Date(decodeToken.exp).getTime())
        //console.log(new Date(decodeToken.iat))
        //console.log(new Date(decodeToken.iat).getTime())
        dispatch(authSuccess(keycloak?.idToken, userId, keycloak?.refreshToken));
      }
    })

    /*
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const storageExpirationDate = localStorage.getItem('expirationDate');
      if (
        storageExpirationDate !== null &&
        storageExpirationDate !== undefined
      ) {
        const expirationDate = new Date(storageExpirationDate);
        if (expirationDate <= new Date()) {
          dispatch(logout());
        } else {
          const userId = localStorage.getItem('userId');
          dispatch(authSuccess(token, userId));
          dispatch(
            checkAuthTimeout(
              (expirationDate.getTime() - new Date().getTime()) / 1000
            )
          );
        }
      }
    }
    */
  };
};

/**
 * @function resetPassword
 * @description reset password
 */
export const resetPassword = (email: string) => {
  return async (dispatch: any) => {
    let url = `${userUrl}/password/reset`;

    const resetCredentials = {
      email: email,
      appName:
        window._env_.THEME_ENV !== 'sanecum' ? 'EASY' : 'SANECUM_MEIN_ARZT',
    };

    try {
      await userClient.post(url, qs.stringify(resetCredentials), config);

      dispatch(resetPasswordSuccess());
    } catch (error) {
      dispatch(resetPasswordFail());
    }
  };
};

/**
 *
 * @method resetPasswordSuccess
 */
export const resetPasswordSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    resetPasswordSuccess: 1,
  };
};

/**
 *
 * @method resetPasswordFail
 */
export const resetPasswordFail = () => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    resetPasswordSuccess: 2,
  };
};

/**
 * @function setNewPassword
 * @description set new password after password forget
 */
export const setNewPassword = (password: string, token: string, project: string) => {
  return async (dispatch: any) => {
    let url = `${userUrl}/password/reset/${token}`;
    if (project) {
      url = `${userUrl.replace('user', project + ".user")}/password/reset/${token}`;
    }

    const resetCredentials = {
      password: password,
    };

    try {
      await userClient.patch(url, qs.stringify(resetCredentials), config);

      dispatch(setNewPasswordSuccess());
    } catch (error) {
      dispatch(setNewPasswordFail());
    }
  };
};

/**
 * @function setNewPassword
 * @description
 */
export const setNewPasswordSuccess = () => {
  return {
    type: actionTypes.SET_NEW_PASSWORD_SUCCESS,
    newPasswordSuccess: true,
  };
};

/**
 * @function setNewPassword
 * @description
 */
export const setNewPasswordFail = () => {
  return {
    type: actionTypes.SET_NEW_PASSWORD_FAIL,
    newPasswordSuccess: false,
  };
};

/**
 * @function validateToken
 * @description validate token from email get params if validate token
 */
export const validateToken = (token: string, project: string) => {
  return async (dispatch: any) => {
    let url = `${userUrl}/password/reset/${token}`;
    if (project) {
      url = `${userUrl.replace('user', project + ".user")}/password/reset/${token}`;
    }


    try {
      const response = await userClient.get(url);

      if (response.status === 200) {
        dispatch(validateTokenSuccess());
      } else if (response.status === 406) {
        dispatch(validateTokenFail());
      }
    } catch (error) {
      // TODO set api is not available or something dispatch(validateTokenFail());
    }
  };
};

/**
 * @function validateTokenSuccess
 * @description
 */
export const validateTokenSuccess = () => {
  return {
    type: actionTypes.VALIDATE_TOKEN_SUCCESS,
    validateTokenSuccess: true,
  };
};

/**
 * @function validateTokenFail
 * @description
 */
export const validateTokenFail = () => {
  return {
    type: actionTypes.VALIDATE_TOKEN_FAIL,
    validateTokenSuccess: false,
  };
};



/**
 * @function setPassword
 * @description set password
 */
export const setPassword = (password: string) => {
  return async (dispatch: any) => {
    let url = `${userUrl}/password/change/`;
    const credentials = {
      newPassword: password,
      oldPassword: localStorage.getItem('tempPassword')
    };

    try {
      const response = await ssoClient.post(
        url,
        qs.stringify(credentials),
      );
      console.log(response)
      if (response) { // response.status === 200 && response.data
        localStorage.removeItem('tempPassword')
        // login again after repsonse true
        const tokenOld = localStorage.getItem('token');
        if (tokenOld !== null) {
          var decoded: any = jwt_decode(tokenOld);
          dispatch(auth(decoded.email, password, null));
        }
      } /*else if (response.status === 406) {
        dispatch(setPasswordFail(password));
      }*/
    } catch (error) {
      // TODO set api is not available or something dispatch(validateTokenFail());
    }
  };
};

/**
 * @function setPasswordSuccess
 * @description set password success
 */
export const setPasswordSuccess = (password: string) => {
  return {
    type: actionTypes.SET_PASSWORD_SUCCESS,
    validateTokenSuccess: false,
  };
};

/**
 * @function setPasswordFail
 * @description set password fail
 */
export const setPasswordFail = (password: string) => {
  return {
    type: actionTypes.SET_PASSWORD_FAIL,
    validateTokenSuccess: false,
  };
};
