import * as actionTypes from './actionTypes';
import { fhirClient, easyClient } from './../../core/auth/services/axios';
import config from './../../config/client';

const fireUrl = config.fhirServerUrl;
const easyUrl = config.easyUrl;

/**
 * init Dashboard
 */
export const initDashboard = () => {
  return async (dispatch: any) => {
    try {
      const response = await fhirClient.get(
        fireUrl + '/fhir/$retrieve-consents-for-patientdashboard'
      );
      dispatch(setDashboard(response.data.parameter));
    } catch (error) {
      dispatch(initDashboardFail());
    }
  };
};

/**
 *
 * @param items
 */
export const setDashboard = (items: any) => {
  return {
    type: actionTypes.SET_DASHBOARD,
    items: items,
  };
};

/**
 * init Dashboard
 */
export const initDashboardFail = () => {
  return {
    type: actionTypes.INIT_DASHBOARD_FAIL,
  };
};

/**
 *
 * @param status
 */
export const fetchDashboardFail = (status: boolean) => {
  return {
    type: actionTypes.FETCH_DASHBOARD_FAIL,
  };
};

/**
 * @description TODO change state and need a extra param status
 * @param id
 */
export const changeConsentItem = (status: any, item: any) => {
  /**
   * @description change status special case first init accept
   */
  if (status === 'active proposed') {
    status = 'active';
    item.part[0].valueString = 'INACTIVE'; // opposite status
  }

  /**
   * @description change status special case first init reject
   */
  if (status === 'inactive proposed') {
    status = 'inactive';
    item.part[0].valueString = 'ACTIVE'; // opposite status
  }

  return async (dispatch: any) => {
    dispatch(changeConsentItemDisable());
    try {
      await fhirClient.post(
        fireUrl + '/fhir/Consent/' + item.id + '/$update-consent-status',
        {
          resourceType: 'Parameters',
          parameter: [
            {
              name: 'status',
              valueCode: status,
            },
          ],
        }
      );
      dispatch(changeConsentItemSuccess(status, item));
    } catch (error) {
      dispatch(changeConsentItemFail(status));
    }
  };
};

/**
 *
 * @param status
 * @param item
 */
export const changeConsentItemDisable = () => {
  return {
    type: actionTypes.CHANGE_CONSENT_ITEM_DISABLE,
    changeConsentDisable: true,
  };
};

/**
 *
 * @param status
 * @param item
 */
export const changeConsentItemSuccess = (status: any, item: any) => {
  return {
    type: actionTypes.CHANGE_CONSENT_ITEM_SUCCESS,
    item: item,
  };
};

/**
 *
 * @param status
 * @param item
 */
export const changeConsentItemFail = (status: any) => {
  return {
    type: actionTypes.CHANGE_CONSENT_ITEM_FAIL,
    status: status,
  };
};

/**
 * @function enablePatient
 * @param state
 * @param action
 */
export const enablePatient = (status: any, item: any) => {
  return async (dispatch: any) => {
    try {
      await easyClient.post(easyUrl + '/patients/enabled');
      dispatch(enablePatientSuccess(true));
      dispatch(changeConsentItem(status, item));
      dispatch(createFirstCase(status, item));
    } catch (error) {
      /**
       * @description enable patient case
       */
      dispatch(createFirstCase(status, item));
    }
  };
};

export const createFirstCase = (status: any, item: any) => {
  const data = {
    title: 'Nierentransplantation',
    description: 'NTX',
    organizationId: '6301d506-78f7-45bb-b4e6-f96536143cfd',
  };

  return async (dispatch: any) => {
    try {
      await easyClient.post(easyUrl + '/cases/first', data);
      dispatch(changeConsentItem(status, item));
    } catch (error) {
      dispatch(changeConsentItem(status, item));
    }
  };
};

/**
 *
 * @param status
 */
export const enablePatientSuccess = (status: boolean) => {
  return {
    type: actionTypes.ENABLE_PATIENT_SUCCESS,
    status: status,
  };
};

/**
 *
 * @param status
 */
export const enablePatientFail = (status: boolean) => {
  return {
    type: actionTypes.ENABLE_PATIENT_FAIL,
    status: status,
  };
};

/**
 *
 * @param status
 */
export const addConsentItemAccessGranting = (status: boolean, item: any) => {
  let credential = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'consentHash',
        valueString: status,
      },
    ],
  };

  return async (dispatch: any) => {
    try {
      const response = await fhirClient.post(
        fireUrl + '/fhir/$get-pending-access-granting-details',
        credential
      );
      dispatch(addConsentItemAccessGrantingSuccess(status, response));
    } catch (error) {
      dispatch(addConsentItemAccessGrantingFail(status));
    }
  };
};

/**
 *
 * @param status
 */
export const addConsentItemAccessGrantingSuccess = (
  status: boolean,
  response: any
) => {
  const dummyConsent = {
    id: response.data.parameter[0].resource.id,
    name: 'Consent/' + response.data.parameter[0].resource.id,
    part: [
      {
        name: 'status',
        valueString: 'PROPOSED', // response.data.parameter[0].resource.status.toUpperCase(),
      },
      {
        name: 'patient',
        valueString: 'Patient/', // TODO missing id
      },
      {
        name: 'organization',
        valueString: response.data.parameter[1].resource.name,
      },
    ],
  };

  return {
    type: actionTypes.ADD_CONSENT_ITEM_ACCESS_GRANTING_SUCCESS,
    newConsentAccessGranting: response.data.parameter[1].resource.name, // get name Organisation
    item: dummyConsent,
  };
};

/**
 *
 * @param status
 */
export const addConsentItemAccessGrantingFail = (status: boolean) => {
  return {
    type: actionTypes.ADD_CONSENT_ITEM_ACCESS_GRANTING_FAIL,
  };
};

/**
 *
 * @param status
 */
export const cancelAddConsentItemFinishGranting = (status: boolean) => {
  return {
    type: actionTypes.CANCEL_ADD_CONSENT_ITEM_FINISH_GRATING,
  };
};

/**
 * @description TODO finish access granting
 *
 */
export const addConsentItemFinishGranting = (newConsent: string) => {
  let credential = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'consentHash',
        valueString: newConsent,
      },
    ],
  };

  return async (dispatch: any) => {
    try {
      const response = await fhirClient.post(
        fireUrl + '/fhir/$finish-access-granting',
        credential
      );
      dispatch(addConsentItemFinishGrantingSuccess(response));
    } catch (error) {
      dispatch(addConsentItemFinishGrantingFail());
    }
  };
};

/**
 * @function addConsentItemFinishGrantingSuccess
 * @description add consent Item Finish Granting Success
 */
export const addConsentItemFinishGrantingSuccess = (response: any) => {
  return {
    type: actionTypes.ADD_CONSENT_ITEM_FINISH_GRANTING_SUCCESS,
  };
};

/**
 * @function addConsentItemFinishGrantingFail
 * @description add consent Item Finish Granting Fail
 */
export const addConsentItemFinishGrantingFail = () => {
  return {
    type: actionTypes.ADD_CONSENT_ITEM_FINISH_GRANTING_FAIL,
  };
};
