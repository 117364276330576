export default {
  keycloak: {
    url:
      process.env.NODE_ENV === "dev"
        ? "https://sso.comjoo.net/auth"
        : process.env.NODE_ENV === "stage"
        ? "https://sso.comjoo.info/auth"
        : "https://sso.comjoo.com/auth",
    realm: "comjoo-hub",
    clientId: "hub-connect",
  },
  userClient: {
    url:
      process.env.NODE_ENV === "dev"
        ? "https://user.api.comjoo.net"
        : process.env.NODE_ENV === "stage"
        ? "https://user.api.comjoo.info"
        : "https://user-api.comjoo.com",
  },
  baseURL:
    process.env.NODE_ENV === "dev"
      ? "https://im.api.comjoo.net"
      : process.env.NODE_ENV === "stage"
      ? "https://im.api.comjoo.info"
      : "https://im-api.comjoo.com",
  fhirServerUrl:
    process.env.NODE_ENV === "dev"
      ? "https://test-server.fhir.medworx.io"
      : process.env.NODE_ENV === "stage"
      ? "https://test.fhir-stage.medworx.io"
      : "https://fhir.medworx.io",
  easyUrl:
    process.env.NODE_ENV === "dev"
      ? "https://pm-easy.api.comjoo.net"
      : process.env.NODE_ENV === "stage"
      ? "https://pm-easy.api.comjoo.info"
      : "https://pm-easy.comjoo.com",
  caseManagement:
    process.env.NODE_ENV === "dev"
      ? "https://signup.api.comjoo.net"
      : process.env.NODE_ENV === "stage"
      ? "https://signup-api.comjoo.info"
      : "https://signup-api.comjoo.com",
  appLinks: {
    android: "https://play.google.com/store/apps/details?id=com.comjoo.easy",
    ios: "https://apps.apple.com/de/app/comjoodoc-easy/id1485413971",
  },
};
